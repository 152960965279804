/* eslint-disable max-lines */
import { useState, useCallback } from 'react';
import { sendNewProjectEmail } from '@services/email.service';
import Button from '@library/Button/Button';
import { validation } from './new-project.utils';

import './new-project.styl';

function NewProject({ setIsCompleted }) {
  const [errorMessage, setErrorMessage] = useState();
  const [emailHasBeenSend, setEmailHasBeenSend] = useState();
  const [formData, setFormData] = useState({
    long_description: '',
    contact: {
      email: '',
    },
  });

  const submitForm = useCallback(() => {
    const valMessage = validation(formData);
    if (valMessage) {
      setErrorMessage(valMessage);
    } else {
      const data = new FormData();
      data.set('data', JSON.stringify(formData));
      sendNewProjectEmail(data);
      setErrorMessage(null);
      setEmailHasBeenSend(true);
      setIsCompleted(true);
    }
  }, [formData, setIsCompleted]);

  if (emailHasBeenSend) {
    return (
      <div className="new-project new-project--success">
        <div className="new-project__header">
          <h2>Submit hydrogen initiatives</h2>
          <p>
            {`Thank you for your input. 
            The Belgian Hydrogen Council will have a look at your request 
            and will be be in touch with you.`}
          </p>
          <div className="new-project__header__logo" />
        </div>
      </div>
    )
  }

  return (
    <div className="new-project">
      <div className="new-project__header">
        <h2>Submit hydrogen initiatives</h2>
        <p>
          Please describe your activity in a few sentences. 
          We will contact you to see together how best to put the activity on the map.
        </p>
        <div className="new-project__header__logo" />
      </div>
      <div className="new-project__body">
        <div className="new-project__body__form">
          <div className="new-project__body__form__group">
            <div className="new-project__body__form__group__field">
              <div className="new-project__body__form__group__field">
                <p className="new-project__body__form__group__field__label">Description *</p>
                <textarea 
                  value={formData.long_description}
                  onChange={e => setFormData(prev => ({ 
                    ...prev, long_description: e.target.value, 
                  }))}
                  className="new-project__body__form__group__field__textarea"
                />
              </div>
            </div>
            <label className="new-project__body__form__group__field">
              <p className="new-project__body__form__group__field__label">Email *</p>
              <input 
                type="email"
                value={formData.contact.email}
                onChange={e => setFormData(prev => ({ 
                  ...prev, contact: { ...prev.contact, email: e.target.value },
                }))}
                className="new-project__body__form__group__field__input"
              />
            </label>
          </div>
          <p className="new-project__body__form__error">{errorMessage}</p>
          <Button
            className="new-project__body__form__submit"
            onClick={() => submitForm()}
          >
            Submit form
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewProject;