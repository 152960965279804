import axios from 'axios';

async function getProjects() {
  const { data: { data: projects } } = await axios.get('/api/projects');
  const FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  
  projects.forEach(({ id, attributes }) => {
    const { geojson, ...properties } = attributes;

    FeatureCollection.features.push({
      type: 'Feature',
      id,
      properties: {
        ...properties,
        value_chain: properties?.value_chain?.data?.id,
        value_chain_full: properties?.value_chain?.data?.attributes?.name,
        status_2024: properties?.status_2024?.data?.id,
        status_2026: properties?.status_2026?.data?.id,
        status_2028: properties?.status_2028?.data?.id,
        status_2030: properties?.status_2030?.data?.id,
      },
      geometry: geojson && geojson.length && geojson[0]?.geometry,
    });
  });

  return FeatureCollection;
}

async function getPipelines() {
  const { data: { data: pipelines } } = await axios.get('/api/pipelines');
  const FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  pipelines.forEach(({ id, attributes }) => {
    const { geojson, ...properties } = attributes;

    FeatureCollection.features.push({
      type: 'Feature',
      id,
      properties: {
        ...properties,
        status_2024: properties?.status_2024?.data?.id,
        status_2026: properties?.status_2026?.data?.id,
        status_2028: properties?.status_2028?.data?.id,
        status_2030: properties?.status_2030?.data?.id,
      },
      geometry: geojson && geojson.length && geojson[0]?.geometry,
    });
  });

  return FeatureCollection;
}

async function getPartners() {
  const { data: { data: partners } } = await axios.get('/api/partners');
  return partners;
}

async function getValueChains() {
  const { data: { data: valueChains } } = await axios.get('/api/value-chains');
  return valueChains;
}

async function getMapOptions() {
  const { data: { data: { attributes: mapSettings } } } = await axios.get('/api/map-options');
  return mapSettings;
}

async function getPipelinePopup() {
  const { data: { data: pipelinePopupData } } = await axios.get('/api/pipeline-popup');
  return pipelinePopupData;
}

async function getProjectStatuses() {
  const { data: { data: projectStatuses } } = await axios.get('/api/project-statuses');
  return projectStatuses;
}

async function getApplications() {
  const { data: { data: applications } } = await axios.get('/api/applications');
  return applications;
}

export {
  getProjects,
  getPipelines,
  getPartners,
  getValueChains,
  getMapOptions,
  getPipelinePopup,
  getProjectStatuses,
  getApplications,
}