import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import TranslationsProvider from '@utils/i18n/i18n';

import appStore from '@App/app.store';
import translator from '@App/app.translator';

import './fullscreen-control.styl';

function toggleFullScreen() {
  const doc = window.document;
  const docEl = doc.documentElement;

  const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen 
    || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen 
    || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (!doc.fullscreenElement && !doc.mozFullScreenElement 
    && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}

class FullscreenControl {
  constructor(options, identifier, type) {
    this._container = null;
    this.options = options;
    this.identifier = identifier;
    this.type = type;
  }

  onAdd() {
    const { identifier, type } = this;

    this._container = document.createElement('div');
    this._container.className = `mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-fullscreen ${type ? `mapboxgl-ctrl-custom--${type}` : ''} mapboxgl-ctrl-${identifier}`;

    const root = createRoot(this._container);
    
    root.render(
      <TranslationsProvider translator={translator}>
        <Provider store={appStore}>
          <button 
            className="mapboxgl-ctrl-icon" 
            type="button" 
            onClick={() => {
              toggleFullScreen();
              this._container.classList.toggle('mapboxgl-ctrl-fullscreen');
              this._container.classList.toggle('mapboxgl-ctrl-shrink');
            }}
          />
        </Provider>
      </TranslationsProvider>,
    );

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default FullscreenControl;