/* eslint-disable max-lines */
import { useEffect, useRef, useState } from 'react';
import { getValueChains, getApplications, getProjectStatuses } from '@services/admin.service';
import { useQuery } from '@tanstack/react-query';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@library/IconButton/IconButton';
import Icon from '@library/Icon/Icon';
import Popup from '@App/pages/MapView/Popup/Popup';

import './overview.styl';

function Overview({
  map, features, filters, setFilters, isShown, searchValue, setSearchValue, activeStatus, 
  setActiveProject, setSidepanelRightIsOpen,
}) {
  const [filterTags, setFilterTags] = useState([]);
  const inputRef = useRef();

  const { data: valueChainData } = useQuery({
    queryKey: ['valueChainData'],
    queryFn: getValueChains,
  });

  const { data: applicationsData } = useQuery({
    queryKey: ['applicationsData'],
    queryFn: getApplications,
  });

  const { data: projectStatusesData } = useQuery({
    queryKey: ['projectStatuses'],
    queryFn: getProjectStatuses,
  });

  useEffect(() => {
    if (!valueChainData || !applicationsData || !projectStatusesData) return;
    const filterTagObjects = [];
    if (filters['value-chain'].length) {
      filters['value-chain'].forEach((t) => {
        const label = valueChainData.find(cd => cd.id === Number(t))?.attributes?.name;
        filterTagObjects.push({ label, value: t, type: 'value-chain' });
      });
    }
    if (filters.application.length) {
      filters.application.forEach((t) => {
        const label = applicationsData.find(cd => cd.id === Number(t))?.attributes?.name;
        filterTagObjects.push({ label, value: t, type: 'application' });
      });
    }
    if (filters.location.place) {
      const locationString = `< ${filters.location.range}km - ${filters.location.place.label}`;
      filterTagObjects.push({ label: locationString, value: filters.location.place.id, type: 'location' })
    }
    if (filters.partners.length) {
      filters.partners.forEach(t => filterTagObjects.push({ label: t.value, value: t.value, type: 'partners' }));
    }
    if (filters.status.length) {
      filters.status.forEach((t) => {
        const label = projectStatusesData.find(cd => cd.id === Number(t))?.attributes?.name;
        filterTagObjects.push({ label, value: t, type: 'status' });
      });
    }
    setFilterTags(filterTagObjects);
  }, [filters, valueChainData, applicationsData, projectStatusesData]);

  return (
    <div className="overview">
      <div className="overview__search">
        <Icon 
          icon={faSearch}
          className="overview__search__icon"
        />
        <input 
          type="text" 
          className="overview__search__input"
          onChange={v => setSearchValue(v.target.value)}
          value={searchValue}
          ref={inputRef}
          placeholder="Search projects"
        />
      </div>
      <div className="overview__filter-tags">
        {filterTags.map((tag) => {
          return (
            <span 
              className="overview__filter-tags__tag" 
              key={`${tag.value}-${tag.type}`}
            >
              {tag.label}
              <IconButton 
                className="overview__filter-tags__tag__icon" 
                icon={faTimes}
                iconColor="#fff"
                onClick={() => {
                  if (tag.type !== 'location' && tag.type !== 'partners') {
                    setFilters({
                      ...filters,
                      [tag.type]: filters[tag.type].filter(v => v !== tag.value),
                    });
                  } else if (tag.type === 'partners') {
                    setFilters({
                      ...filters,
                      [tag.type]: filters[tag.type].filter(v => v.value !== tag.value),
                    });
                  } else if (tag.type === 'location') {
                    setFilters({
                      ...filters,
                      [tag.type]: {
                        ...filters[tag.type],
                        place: null,
                      },
                    });
                  }
                }}
              />
            </span>
          )
        })}
      </div>
      <div className="overview__features">
        <p className="overview__features__total">
          {`${features.length} result${features.length !== 1 ? 's' : ''} found`}
        </p>
        {features.length ? (
          <ul className="overview__features__list">
            {features.map((f) => {
              return (
                <li className="overview__features__list__item" key={f.id}>
                  <Popup
                    map={map}
                    className="overview__features__list__item__popup"
                    project={f}
                    activeStatus={activeStatus}
                    sidepanelLeftIsOpen={isShown}
                    setActiveProject={setActiveProject}
                    setSidepanelRightIsOpen={setSidepanelRightIsOpen}
                  />
                </li>
              )
            })}
          </ul>
        ) : (
          <div className="overview__features__empty" />
        )}
      </div>
    </div>
  )
}

export default Overview;
