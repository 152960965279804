import { useRef } from 'react';

import { generateUniqueId } from '@services/utils.service'

import './checkbox.styl';

function Checkbox(props) {
  const {
    label, name, checked, className, onChange, 
  } = props;
  const idRef = useRef(generateUniqueId());

  return (
    <div className={`checkbox ${className || ''}`}>
      <input
        id={idRef.current}
        className="checkbox__input"
        type="checkbox"
        name={name}
        checked={!!checked}
        onChange={e => onChange(e, e.target.checked, e.target.name)}
      />
      <label htmlFor={idRef.current} className="checkbox__label">
        { label }
      </label>
    </div>
  );
}

export default Checkbox;