import Button from '@library/Button/Button';

import './status-selector.styl';

function StatusSelector({ activeStatus, setActiveStatus, className }) {
  return (
    <div className={`status-selector${className ? ` ${className}` : ''}`}>
      <Button 
        disableElevation 
        className={`status-selector__option ${activeStatus === '2024' && 'status-selector__option--active'}`}
        onClick={() => setActiveStatus('2024')}
      >
        Up to now
      </Button>
      <Button 
        disableElevation 
        className={`status-selector__option ${activeStatus === '2026' && 'status-selector__option--active'}`}
        onClick={() => setActiveStatus('2026')}
      >
        2026
      </Button>
      <Button 
        disableElevation
        className={`status-selector__option ${activeStatus === '2028' && 'status-selector__option--active'}`}
        onClick={() => setActiveStatus('2028')}
      >
        2028
      </Button>
      <Button 
        disableElevation
        className={`status-selector__option ${activeStatus === '2030' && 'status-selector__option--active'}`}
        onClick={() => setActiveStatus('2030')}
      >
        <span>{'> '}</span>
        2030
      </Button>
    </div>
  )
}

export default StatusSelector;