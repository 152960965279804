import axios from 'axios';

async function saveCookieConsent(acceptedCookiesArray) {
  try {
    if (import.meta.env.VITE_NAZKA_ENV === 'production') {
      await axios.post(
        'https://cookieservice.prod.lambda.nazkamapps.com',
        `{"website":"${window.location.hostname}", "acceptedCookies":${JSON.stringify(acceptedCookiesArray)}}`,
        { headers: { 'Content-Type': 'application/json;charset=UTF-8', Accept: 'application/json, text/plain, */*' } },
      );
    }
  } catch (err) {
    console.error(err);
  }
}

export {
  saveCookieConsent,
}