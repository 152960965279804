/* eslint-disable max-lines */
import { useRef, useEffect, useMemo } from 'react';
import IconButton from '@library/IconButton/IconButton';
import { getValueChains, getPipelinePopup, getMapOptions } from '@services/admin.service';
import { useQuery } from '@tanstack/react-query'

import style from '@css/theme.style.json';
import './popup.styl';

function Popup({
  className, map, project, activeStatus, sidepanelLeftIsOpen, setActiveProject, 
  setSidepanelRightIsOpen, sidepanelRightIsOpen,
}) {
  const prevProject = useRef();

  const { data: valueChainData } = useQuery({
    queryKey: ['valueChainData'],
    queryFn: getValueChains,
  });

  const { data } = useQuery({
    queryKey: ['pipelinePopupData'],
    queryFn: getPipelinePopup,
  });

  const { data: mapOptions } = useQuery({
    queryKey: ['mapOptions'],
    queryFn: getMapOptions,
  });

  const pipelines = data?.attributes;

  const prevImages = useRef();
  const images = useMemo(() => {
    if (!project || !valueChainData) return prevImages.current;
    let imgs = [];

    if (project?.properties?.slug) {
      // Check if project has img available
      imgs = mapOptions?.show_project_photo && project?.properties?.photo?.data
        ? project?.properties?.photo?.data.map(img => (
          img?.attributes?.formats?.small?.url || img?.attributes?.url
        )) 
        : [];
      if (!imgs.length) {
        // Else set placeholder based on value chain
        imgs = valueChainData.filter((v) => {
          return v.attributes.name === project?.properties?.value_chain_full;
        });
        imgs = imgs.map(img => (
          img?.attributes?.default_image?.data?.attributes?.formats?.small?.url
          || img?.attributes?.default_image?.data?.attributes?.url
        ));
      }
    } else {
      // Active project is pipeline
      imgs = pipelines?.photo?.data?.attributes?.formats?.small?.url 
        ? [
          pipelines?.photo?.data?.attributes?.formats?.small?.url
          || pipelines?.photo?.data?.attributes?.url,
        ] 
        : [];
    }
    prevImages.current = imgs;
    return imgs;
  }, [project, valueChainData, mapOptions, pipelines]);

  useEffect(() => {
    if (project) prevProject.current = project;
  }, [project]);

  const properties = (project?.properties || prevProject.current?.properties);
  const statusColor = style?.color?.project?.[properties?.[`status_${activeStatus}`]];

  if (project?.geometry?.type === 'Point' || (!project && prevProject.current?.geometry?.type === 'Point')) {
    return (
      <div 
        className={
          `popup ${project && !className && !sidepanelRightIsOpen ? ' popup--show' : ''}
          ${className ? ` ${className}` : ''}
          ${sidepanelLeftIsOpen && !className ? ' popup--filter-is-open' : ''}`
        }
        onClick={() => {
          setSidepanelRightIsOpen(true);
          if (className) {
            map.flyTo({
              zoom: 10,
              center: project.geometry.coordinates,
              essential: true,
            });
            setActiveProject(project);
          }
        }}
      >
        <div className="popup__header">
          <div 
            className="popup__header__marker" 
            style={{ backgroundImage: `url(/img/markers/value-chain-${properties?.value_chain}.png)` }}
          >
            <span 
              className="popup__header__marker__status"
              style={{ backgroundColor: statusColor }}
            />
          </div>
          <div className="popup__header__text">
            {properties?.value_chain_full}
          </div>
        </div>
        <div className="popup__content">
          <div className="popup__content__text">
            <div className="popup__content__text__title">
              {properties?.name}
            </div>
            <div className="popup__content__text__body">
              {properties?.short_description}
            </div>
          </div>
          <div
            className="popup__content__photo" 
            style={{ backgroundImage: `url(${images?.[0]})` }}
          />
          <IconButton 
            className={`popup__content__button ${statusColor}`}
            icon="chrevon-right.svg"
            iconColor={!statusColor.includes('#FFFFFF') ? 'white' : style?.color.common.grey.normal}
            style={{ 
              backgroundColor: statusColor,
              borderTop: !statusColor.includes('#FFFFFF') ? '' : `${style?.color.common.grey.normal} solid 0.5px`,
              borderLeft: !statusColor.includes('#FFFFFF') ? '' : `${style?.color.common.grey.normal} solid 0.5px`,
              borderBottom: !statusColor.includes('#FFFFFF') ? '' : `${style?.color.common.grey.normal} solid 0.5px`,
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div 
      className={
        `popup popup--pipeline ${project && !className && !sidepanelRightIsOpen ? ' popup--show' : ''}
        ${className ? ` ${className}` : ''}
        ${sidepanelLeftIsOpen && !className ? ' popup--filter-is-open' : ''}`
      }
      onClick={() => setSidepanelRightIsOpen(true)}
    >
      <div className="popup__header">
        <div 
          className="popup__header__marker" 
          style={{ backgroundImage: `url(/img/markers/value-chain-${pipelines?.value_chain?.data?.id}.png` }}
        >
          <span 
            className="popup__header__marker__status"
            style={{ backgroundColor: statusColor }}
          />
        </div>
        <div className="popup__header__text">
          {pipelines?.value_chain?.data?.attributes?.name}
        </div>
      </div>
      <div className="popup__content">
        <div className="popup__content__text">
          <div className="popup__content__text__title">
            {pipelines?.name}
          </div>
          <div className="popup__content__text__body">
            {pipelines?.short_description}
          </div>
        </div>
        <div
          className="popup__content__photo" 
          style={{ backgroundImage: `url(${images?.[0]})` }}
        />
        <IconButton 
          className="popup__content__button"
          icon="chrevon-right.svg"
          iconColor={style?.color.common.grey.normal}
          style={{
            borderTop: `${style?.color.common.grey.normal} solid 0.5px`,
            borderLeft: `${style?.color.common.grey.normal} solid 0.5px`,
            borderBottom: `${style?.color.common.grey.normal} solid 0.5px`,
          }}
        />
      </div>
    </div>
  )
}

export default Popup;