import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { RWebShare } from 'react-web-share';
import { getValueChains, getMapOptions, getPipelinePopup } from '@services/admin.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@library/IconButton/IconButton';
import ImageSlider from '../ImageSlider/ImageSlider';
import ProjectInfo from './ProjectInfo/ProjectInfo';
import PipelineInfo from './PipelinesInfo/PipelinesInfo';

import './sidepanel-right.styl';

function SidepanelRight({
  activeProject, sidepanelRightIsOpen, setSidepanelRightIsOpen, setFormIsOpen,
  setSidepanelLeftIsOpen,
}) {
  const location = useLocation();
  const scrollEl = useRef();
  const { data: valueChainData } = useQuery({
    queryKey: ['valueChainData'],
    queryFn: getValueChains,
  });
  const { data: mapOptions } = useQuery({
    queryKey: ['mapOptions'],
    queryFn: getMapOptions,
  });
  const { data } = useQuery({
    queryKey: ['pipelinePopupData'],
    queryFn: getPipelinePopup,
  });
  const pipelines = data?.attributes;

  const shareData = useMemo(() => {
    if (!activeProject) return null;
    if (scrollEl.current) scrollEl.current.scrollTop = 0;
    return {
      title: activeProject?.properties?.name,
      text: 'BHC | Interactive hydrogen map',
      url: navigator.share ? location.pathname : window.location.href,
    } 
  }, [location, activeProject]);

  const images = useMemo(() => {
    if (!activeProject || !valueChainData) return null;
    let imgs = [];

    if (activeProject?.properties?.slug) {
      // Check if project has img available
      imgs = mapOptions?.show_project_photo && activeProject?.properties?.photo?.data
        ? activeProject?.properties?.photo?.data.map(img => (
          img?.attributes?.formats?.medium?.url || img?.attributes?.url
        )) 
        : [];
      if (!imgs.length) {
        // Else set placeholder based on value chain
        imgs = valueChainData.filter((v) => {
          return v.attributes.name === activeProject?.properties?.value_chain_full;
        });
        imgs = imgs.map(img => (
          img?.attributes?.default_image?.data?.attributes?.formats?.medium?.url
          || img?.attributes?.default_image?.data?.attributes?.url
        ));
      }
    } else {
      // Active project is pipeline
      imgs = pipelines?.photo?.data?.attributes?.formats?.medium?.url 
        ? [
          pipelines?.photo?.data?.attributes?.formats?.medium?.url
          || pipelines?.photo?.data?.attributes?.url,
        ] 
        : [];
    }
    return imgs;
  }, [activeProject, valueChainData, mapOptions, pipelines]);
  
  return (
    <div className={`sidepanel-right${sidepanelRightIsOpen ? ' sidepanel-right--open' : ''}`}>
      <IconButton 
        className="sidepanel-right__close" 
        icon={faTimes}
        iconColor="#222"
        onClick={() => setSidepanelRightIsOpen(false)}
      />
      <div className="sidepanel-right__no-overflow">
        <div className="sidepanel-right__scroller" ref={scrollEl}>
          {mapOptions?.show_project_photo && (
            <div className="sidepanel-right__scroller__header">
              {images && images.length < 2 && (
                <span style={{ backgroundImage: `url(${images?.[0]})` }} />
              )}
              {images && images.length >= 2 && (
                <ImageSlider 
                  id="sidepanel-right-slider"
                  images={images}
                  showBullets
                />
              )}
              <div className="sidepanel-right__scroller__header__nav">
                {shareData?.title && (
                  <RWebShare 
                    data={shareData}
                    sites={['mail', 'whatsapp', 'telegram', 'copy']}
                  >
                    <IconButton 
                      className="sidepanel-right__scroller__header__nav__share" 
                      icon="share.svg"
                    />
                  </RWebShare>
                )}
              </div>
            </div>
          )}
          <div className="sidepanel-right__scroller__body">
            {activeProject?.properties?.slug ? (
              <ProjectInfo 
                activeProject={activeProject}
                mapOptions={mapOptions}
                setFormIsOpen={setFormIsOpen}
                setSidepanelLeftIsOpen={setSidepanelLeftIsOpen}
                setSidepanelRightIsOpen={setSidepanelRightIsOpen}
                shareData={shareData}
              />
            ) : (
              <PipelineInfo
                mapOptions={mapOptions}
                setFormIsOpen={setFormIsOpen}
                setSidepanelLeftIsOpen={setSidepanelLeftIsOpen}
                setSidepanelRightIsOpen={setSidepanelRightIsOpen}
                shareData={shareData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidepanelRight;