import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { getSuggestions } from '@services/geocoding.service';
import Icon from '@library/Icon/Icon';
import Select from 'react-select';
import Search from './Search/Search';

import './location-select.styl';

const chevronDownIcon = () => (
  <Icon icon={faChevronDown} size={{ height: '14px', width: '35px' }} color="#333" />
);

async function onSearch(query) {
  const { features } = await getSuggestions(query);
  return features.map(f => ({
    id: f.id, label: f.place_name, data: f,
  }));
}

const options = [
  { value: 10, label: '< 10km' },
  { value: 25, label: '< 25km' },
  { value: 50, label: '< 50km' },
  { value: 100, label: '< 100km' },
]

function LocationSelect({ filters, setFilters }) {
  function onItemSelect(item) {
    setFilters({ ...filters, location: { ...filters.location, place: item || null } });
  }

  return (
    <div className="location-select">
      <div className="location-select__search">
        <Search dataSources={[{ index: 0, onSearch, onItemSelect }]} filters={filters} />
      </div>
      <div className="location-select__range">
        <Select 
          value={options.find(o => o.value === filters.location.range)}
          options={options}
          onChange={o => setFilters(
            { ...filters, location: { ...filters.location, range: o.value } },
          )}
          className="location-select__range__select"
          classNamePrefix="location-select__range__select"
          components={{ 
            IndicatorSeparator: () => null,
            DropdownIndicator: chevronDownIcon,
          }}
        />
      </div>
    </div>
  )
}

export default LocationSelect;