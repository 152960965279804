import { screen } from '@css/theme.style.json';
import { convertPxToNum } from './app-window.utils';

const MEDIA_BREAKPOINT_ENTRIES = [
  [0, 'XXXS'],
  [convertPxToNum(screen.xxs), 'XXS'],
  [convertPxToNum(screen.xs), 'XS'],
  [convertPxToNum(screen.sm), 'SM'],
  [convertPxToNum(screen.md), 'MD'],
  [convertPxToNum(screen.lg), 'LG'],
  [convertPxToNum(screen.xlg), 'XLG'],
  [Infinity],
];

export {
  MEDIA_BREAKPOINT_ENTRIES,
}