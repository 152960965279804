import { getLoaderClass } from '@library/Button/button.utils';

import './button.styl';

function Button(props) {
  const {
    isLoading, classType, className, disabled, disableElevation,
  } = props;
  const typeClass = `${classType ? `button--${classType}` : ''}`;
  const shadowClass = `${['flat', 'outlined'].includes(classType) || disableElevation ? '' : 'z-depth-1'}`;

  return (
    <button 
      type="button"
      className={
        'button hover-effect '
        + `${typeClass} ` 
        + `${shadowClass} `
        + `${isLoading ? `${getLoaderClass(classType)} ` : ''}`
        + `${className || ''}`
      } 
      disabled={disabled || false}
      onClick={props.onClick || (e => e.preventDefault())}
    >
      {props.children}
    </button>
  );
}

export default Button;