import { faSearch, faSlidersV } from '@fortawesome/pro-light-svg-icons';
import Button from '@library/Button/Button';
import Icon from '@library/Icon/Icon';
import StatusSelector from '../SidepanelLeft/StatusSelector/StatusSelector';

import './data-controls.styl';

function DataControls({
  activeStatus, setActiveStatus, sidepanelLeftIsOpen, setSidepanelLeftIsOpen, searchValue, 
  setSidepanelLeftContent,
}) {
  return (
    <div className={`data-controls${sidepanelLeftIsOpen ? ' data-controls--sidepanel-is-open' : ''}`}>
      <StatusSelector 
        activeStatus={activeStatus} 
        setActiveStatus={setActiveStatus}
        className="data-controls__status"
      />
      <div className="data-controls__menu">
        <Button 
          className="data-controls__menu__button"
          disableElevation
          onClick={() => {
            setSidepanelLeftContent('filter');
            setSidepanelLeftIsOpen(true);
          }}
        >
          <Icon icon={faSlidersV} />
          <span className="data-controls__menu__button__text">Filter</span>
        </Button>
        <div 
          className="data-controls__menu__search"
          onClick={() => {
            setSidepanelLeftContent('search');
            setSidepanelLeftIsOpen(true);
          }}
        >
          <Icon 
            icon={faSearch}
            className="data-controls__menu__search__icon"
          />
          <input readOnly value={searchValue} type="text" className="data-controls__menu__search__input" />
        </div>
      </div>
    </div>
  )
}

export default DataControls;