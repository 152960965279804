import Cookies from 'js-cookie';

import { saveCookieConsent } from '@services/cookie.service';
import { rootDomain } from '@services/utils.service';
import cookies from './settings/cookies.config';

function reduceToAcceptedCategories(cookieCategorySelection) {
  return cookieCategorySelection.reduce((acc, curr) => {
    if (!curr.accepted) {
      return acc;
    }

    return acc.concat([curr.id]);
  }, []);
}

function getAcceptedCookies(categoryCookies) {
  return cookies
    .map((curr) => {
      if (curr.name !== 'cookie-agreed') {
        return { ...curr };
      }

      return { ...curr, value: reduceToAcceptedCategories(categoryCookies).join() }
    })
    .filter((curr) => {
      const cookieCategory = categoryCookies.filter(
        category => (category.id === curr.category),
      )[0];
      
      return cookieCategory.accepted;
    });
}

/*  EXPORTS
    =============================================================== */
const CookiesApi = Cookies.withAttributes({ path: '/', domain: `.${rootDomain(window.location.hostname)}` });
// const CookiesApi = Cookies;

async function submitCookies(cookieCategorySelection) {
  const acceptedCategoryNames = reduceToAcceptedCategories(cookieCategorySelection);
  const acceptedCookies = getAcceptedCookies(cookieCategorySelection);
  
  await saveCookieConsent(acceptedCategoryNames);

  return acceptedCookies;
}

function daysFromNow(date) {
  const today = new Date();
  const timeinmilisec = date.getTime() - today.getTime();
  
  return Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
}

export {
  CookiesApi,
  submitCookies,
  daysFromNow,
};