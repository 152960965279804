import ReactDOMServer from 'react-dom/server';
import { sanitizeHTML } from '@services/utils.service';
// import parse from 'react-html-parser';

function handlePlural(translatedStrings, data, pluralization, debug) {
  try {
    const value = data.plural;
  
    const key = Array.isArray(value)
      ? value.map(val => pluralization.f(val, pluralization)).join('.')
      : pluralization.f(value, pluralization);
    return translatedStrings[key];
  } catch (err) {
    if (debug) console.warn('i18n: ERROR - Something went wrong handling plural for: ', { translatedStrings, data, pluralization }, err);
    return Object.values(translatedStrings)[0];
  }
}

function parseVariables(text, data, debug) {
  try {
    const middleArray = text.split('{{');
    const keys = middleArray.filter(str => str.includes('}}')).map(el => el.split('}}')[0]);
    const textArray = middleArray.map(el => (el.includes('}}') ? el.split('}}')[1] : el));

    const result = textArray
      .reduce((acc, curr, index) => {
        acc.push(curr);
        if (Object.keys(data).indexOf(keys[index]) >= 0) acc.push(data[keys[index]]);
        return acc;
      }, [])
      .filter(el => el !== '');
    return result;
  } catch (err) {
    if (debug) console.warn('i18n: ERROR - Something went wrong parsing variables for: ', { string: text, data }, err);
    return text;
  }
}

function translateString(stringId, translatedStrings, data, pluralization, config, loading) {
  const { debug, nsSeparator } = config;
  let text = stringId;
  
  try {
    const keys = config.useNameSpaces ? stringId.split(nsSeparator) : [stringId];

    if (!translatedStrings) { // if translatedStrings are not available, return id
      if (debug && !loading) console.warn('i18n: ERROR - Translation in state missing.'); // if text is loading, not an error
      if (data) text = parseVariables(keys[keys.length - 1], data, debug);
      return text;
    }
    
    text = keys
      .reduce((acc, curr) => acc[curr], translatedStrings);

    if (typeof text === 'object') {
      text = handlePlural(text, data, pluralization, debug);
    }
    
    if (!text) { // if text is not found, return id
      if (debug) console.warn('i18n: ERROR - No translation found for ', { stringId, translatedStrings });
      text = keys[keys.length - 1];
    }

    if (data) { // if variables need to be parsed
      text = parseVariables(text, data, debug);
      if (Array.isArray(text)) {
        text = text
          .map(el => (typeof el === 'object' ? ReactDOMServer.renderToString(el) : el))
          .reduce((acc, el) => `${acc}${el}`, '');
      }
    }

    // if there are HTML elements inside the translation:

    // without react-html-parser, we check there are dom elements in the text 
    // and inject it in a span that we return

    const parentNode = document.createElement('span');
    parentNode.innerHTML = text;
    if (parentNode?.childNodes?.length > 1 || parentNode?.childNodes[0].nodeType !== 3) {
      const sanitizedText = sanitizeHTML(text);
      // eslint-disable-next-line react/no-danger
      text = <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
    }

    // // with react-html-parser we just parse the string
    // text = parse(text);
    // // because parse will always return an array, if it contains one el we return that instead
    // if (text.length === 1) [text] = text;
  } catch (err) {
    if (debug) {
      console.warn(
        'i18n: ERROR - Something went wrong finding translation', 
        { 
          stringId, translatedStrings, data, pluralization, config, loading, 
        }, 
        err,
      );
    }
    text = parseVariables(text, data, debug);
  }
  
  return text;
}

export {
  translateString,
}