import { useState, useEffect } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@library/IconButton/IconButton';
import EditProject from './EditProject/EditProject';
import NewProject from './NewProject/NewProject';

import './form.styl';

function Form({ formIsOpen, setFormIsOpen }) {
  const [isCompleted, setIsCompleted] = useState();

  useEffect(() => {
    setIsCompleted(false);
  }, [formIsOpen]);

  return (
    <div className={`form${formIsOpen ? ' form--is-open' : ''}${formIsOpen === true ? ' form--new-project' : ''}${isCompleted ? ' form--is-completed' : ''}`}>
      <IconButton
        className="form__close" 
        icon={faTimes}
        iconColor="#222"
        onClick={() => setFormIsOpen(false)}
      />
      <div className="form__scroller">
        {formIsOpen === true ? (
          <NewProject setIsCompleted={setIsCompleted} />
        ) : (
          <EditProject setIsCompleted={setIsCompleted} project={formIsOpen} />
        )}
      </div>
    </div>
  )
}

export default Form;