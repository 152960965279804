import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { onResizeViewport } from './app-window.utils';
import { updateResizeProps } from './app-window.redux.actions';

function AppWindow() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    function onResize() {
      dispatch(updateResizeProps(onResizeViewport()));
    }

    const debouncedResize = debounce(onResize, 250);
    debouncedResize();

    window.addEventListener('resize', debouncedResize);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    }
  }, [dispatch]);

  return null;
}

export default AppWindow;
