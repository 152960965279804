import { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import TranslationsProvider, { LocalizedRoutes } from '@utils/i18n/i18n';
import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import CookieBar from '@utils/CookieBar/CookieBar';

import routes from '@App/app.routes';
import store from '@App/app.store';
import translator from '@App/app.translator';

import './app.styl';

const queryClient = new QueryClient()

function App() {
  useEffect(() => document.documentElement.setAttribute('data-app-initialised', true), []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <TranslationsProvider translator={translator}>
              <LocalizedRoutes>
                {routes.map(({ path, element }) => (
                  <Route path={path} element={element} key={path} />
                ))}
              </LocalizedRoutes>
              <AppWindow />
              <CookieBar />
            </TranslationsProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
