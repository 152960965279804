// Make sure the category id is unique and isn't a substring of any other category id.

const categories = [
  {
    id: 'essential',
    label: 'Esential cookies',
    description: 'These cookies are necessary for the good functioning and a good user experience of this website.',
    readOnly: true,
    required: true,
  },
  {
    id: 'analytical',
    label: 'Analytical cookies',
    description: 'These cookies are used to gather statistics on how and how much the website is being used. This information is anonymized and is only being used to improve the content of our website.',
    required: false,
  },
]

export default categories;