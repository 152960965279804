import { useEffect, useRef } from 'react';
import Glide from '@glidejs/glide';
import Icon from '@library/Icon/Icon';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';

import './image-slider.styl';

function ImageSlider(props) {
  const { id, images, showBullets } = props;
  const glideRef = useRef();

  useEffect(() => {
    glideRef.current = new Glide(`.js-${id}`, {
      type: 'slider',
    }).mount();

    return () => glideRef.current.destroy();
  }, [id])

  return (
    <div className={`js-${id} image-slider glide`}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {images.map((curr) => {
            return (
              <li 
                key={curr} 
                className="glide__slide"
              >
                <img src={curr} alt="" />
              </li>
            )
          })}
        </ul>
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        <button
          type="button"
          className="glide__arrow glide__arrow--left z-depth-2"
          data-glide-dir="<"
        >
          <Icon icon="slider-chevron-left.png" size="25px" />
        </button>
        <button
          type="button"
          className="glide__arrow glide__arrow--right z-depth-2"
          data-glide-dir=">"
        >
          <Icon icon="slider-chevron-right.png" size="25px" />
        </button>
      </div>
      {showBullets && (
        <div className="glide__bullets" data-glide-el="controls[nav]">
          {images.map((_, i) => <button type="button" key={i} className="glide__bullet" data-glide-dir={`=${i}`} />)}
        </div>
      )}
    </div>
  )
}

export default ImageSlider;