import { useContext } from 'react';
import { I18nConsumer, i18n } from './i18n.context';

const useTranslation = () => {
  const { 
    lang, languages, setLanguage, translate,
  } = useContext(i18n);
  return { 
    lang, languages, setLang: setLanguage, t: translate,
  };
}

const withTranslation = WrappedComponent => function withTranslationFn(props) {
  return (
    <I18nConsumer>
      {({
        lang, languages, translate, setLanguage,
      }) => {
        return (
          <WrappedComponent 
            {...props}
            lang={lang} 
            languages={languages}
            setLang={setLanguage}
            t={translate}
          />
        );
      }}
    </I18nConsumer>
  )
}

export {
  useTranslation,
  withTranslation,
}
