/*  EXPORTS
    =============================================================== */
function prepCategories(categories) {
  return categories.map((curr) => { 
    const { accepted, required } = curr;
    
    return { ...curr, accepted: (typeof accepted === 'boolean') ? accepted : required };
  });
}

export {
  prepCategories,
};