import { faSearch, faSlidersV, faTimes } from '@fortawesome/pro-light-svg-icons';
import Icon from '@library/Icon/Icon';
import IconButton from '@library/IconButton/IconButton';
import Button from '@library/Button/Button';
import StatusSelector from './StatusSelector/StatusSelector';
import Overview from './Overview/Overview';
import Filter from './Filter/Filter';

import './sidepanel-left.styl';

function SidepanelLeft({
  map, features, activeStatus, setActiveStatus, sidepanelLeftIsOpen, setSidepanelLeftIsOpen, 
  searchValue, setSearchValue, activeProject, filters, setFilters, setActiveProject, 
  setSidepanelRightIsOpen, sidepanelLeftContent, setSidepanelLeftContent,
}) {
  return (
    <div className={`sidepanel-left${sidepanelLeftIsOpen ? ' sidepanel-left--open' : ''}${sidepanelLeftContent === 'filter' ? ' sidepanel-left--filter' : ''}`}>
      <Button 
        className={`sidepanel-left__display-results${sidepanelLeftContent === 'search' ? ' sidepanel-left__display-results--search' : ''}`}
        onClick={() => {
          setSidepanelLeftIsOpen(false);
        }}
      >
        {`Display ${features.length} result${features.length !== 1 ? 's' : ''}`}
      </Button>
      <IconButton 
        className="sidepanel-left__close" 
        icon={faTimes}
        iconColor="#222"
        onClick={() => setSidepanelLeftIsOpen(false)}
      />
      <div className="sidepanel-left__overflow-wrapper">
        <div className="sidepanel-left__scroller">
          <div className="sidepanel-left__scroller__header">
            <div className="sidepanel-left__scroller__header__nav">
              <Button 
                className="sidepanel-left__scroller__header__nav__button" 
                iconColor="#333"
                disableElevation
                onClick={() => {
                  setSidepanelLeftContent(prev => (prev === 'filter' ? 'search' : 'filter'))
                }}
              >
                <Icon icon={sidepanelLeftContent === 'filter' ? faSearch : faSlidersV} />
                <span className="sidepanel-left__scroller__header__nav__button__text">
                  {sidepanelLeftContent === 'search' ? 'Filter' : 'Search'}
                </span>
              </Button>
            </div>
            <div className="sidepanel-left__scroller__header__logo" />
          </div>
          <div className="sidepanel-left__scroller__body">
            <StatusSelector activeStatus={activeStatus} setActiveStatus={setActiveStatus} />
            {sidepanelLeftContent === 'filter' ? (
              <Filter 
                filters={filters} 
                setFilters={setFilters} 
                sidepanelLeftIsOpen={sidepanelLeftContent}
              />
            ) : (
              <Overview 
                map={map}
                features={features}
                filters={filters}
                setFilters={setFilters}
                activeProject={activeProject}
                activeStatus={activeStatus}
                isShown={sidepanelLeftContent}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setActiveProject={setActiveProject}
                setSidepanelRightIsOpen={setSidepanelRightIsOpen}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidepanelLeft;