import { useState, useEffect } from 'react';
import Select from 'react-select';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import Icon from '@library/Icon/Icon';
import { useQuery } from '@tanstack/react-query';
import { getPartners } from '@services/admin.service';

import './partner-select.styl';

const chevronDownIcon = () => (
  <Icon icon={faChevronDown} size={{ height: '14px', width: '35px' }} color="#333" />
);

function PartnerSelect({ filters, setFilters }) {
  const [options, setOptions] = useState([]);
  const { data: partnersData } = useQuery({
    queryKey: ['partnersData'],
    queryFn: getPartners,
  });

  useEffect(() => {
    if (!partnersData) return;
    setOptions(partnersData.map(p => (
      { value: p.attributes.name, label: p.attributes.name }
    )))
  }, [partnersData]);

  return (
    <div className="partner-select">
      <Select 
        isMulti
        value={filters.partners}
        options={options}
        onChange={o => setFilters(
          { ...filters, partners: o },
        )}
        className="partner-select__select"
        classNamePrefix="partner-select__select"
        components={{ 
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          DropdownIndicator: chevronDownIcon,
        }}
        maxMenuHeight={140}
      />
    </div>
  )
}

export default PartnerSelect;