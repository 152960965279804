import ValueChainSelect from './ValueChainSelect/ValueChainSelect';
import ApplicationSelect from './ApplicationSelect/ApplicationSelect';
import LocationSelect from './LocationSelect/LocationSelect';
import PartnerSelect from './PartnerSelect/PartnerSelect';
import StatusSelect from './StatusSelect/StatusSelect';

import './filter.styl';

function Filter({
  filters, setFilters,
}) {
  return (
    <div className="filter">
      <div className="filter__group">
        <div className="filter__group__header">
          <h5 className="filter__group__header__title">
            Value Chain
          </h5>
          <button 
            type="button" 
            className="filter__group__header__reset"
            onClick={() => setFilters({ ...filters, 'value-chain': [] })}
          >
            Reset
          </button>
        </div>
        <div className="filter__group__content">
          <ValueChainSelect filters={filters} setFilters={setFilters} />
        </div>
      </div>
      <div className="filter__group">
        <div className="filter__group__header">
          <h5 className="filter__group__header__title">
            Application
          </h5>
          <button 
            type="button" 
            className="filter__group__header__reset"
            onClick={() => setFilters({ ...filters, application: [] })}
          >
            Reset
          </button>
        </div>
        <div className="filter__group__content">
          <ApplicationSelect filters={filters} setFilters={setFilters} />
        </div>
      </div>
      <div className="filter__group" style={{ zIndex: 99 }}>
        <div className="filter__group__header">
          <h5 className="filter__group__header__title">
            Location
          </h5>
          <button 
            type="button" 
            className="filter__group__header__reset"
            onClick={() => setFilters(
              { ...filters, location: { place: null, range: 10 } },
            )}
          >
            Reset
          </button>
        </div>
        <div className="filter__group__content">
          <LocationSelect filters={filters} setFilters={setFilters} />
        </div>
      </div>
      <div className="filter__group">
        <div className="filter__group__header">
          <h5 className="filter__group__header__title">
            Partners
          </h5>
          <button 
            type="button" 
            className="filter__group__header__reset"
            onClick={() => setFilters(
              { ...filters, partners: [] },
            )}
          >
            Reset
          </button>
        </div>
        <div className="filter__group__content">
          <PartnerSelect filters={filters} setFilters={setFilters} />
        </div>
      </div>
      <div className="filter__group">
        <div className="filter__group__header">
          <h5 className="filter__group__header__title">
            Status
          </h5>
          <button 
            type="button" 
            className="filter__group__header__reset"
            onClick={() => setFilters(
              { ...filters, status: [] },
            )}
          >
            Reset
          </button>
        </div>
        <div className="filter__group__content">
          <StatusSelect filters={filters} setFilters={setFilters} />
        </div>
      </div>
    </div>
  )
}

export default Filter;
