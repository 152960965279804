if (!import.meta.env.VITE_GA_MEASUREMENT_ID) {
  console.log('Google Analytics isn\'t setup for production yet.');
}

function initGoogleAnalytics() {
  if (import.meta.env.VITE_GA_MEASUREMENT_ID) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() { dataLayer.push(arguments); }
    
    gtag('js', new Date());

    gtag('config', import.meta.env.VITE_GA_MEASUREMENT_ID, {
      debug_mode: true,
    });
  }
}

export { initGoogleAnalytics };