function onResizeViewport() {
  const vh = window.innerHeight * 0.01;
  const vw = window.innerWidth * 0.01;
  document.body.style.setProperty('--vh', `${vh}px`);
  document.body.style.setProperty('--vw', `${vw}px`);
  return {
    '--vh': vh,
    '--vw': vw,
  };
}

function convertPxToNum(val) {
  const numString = val.replace('px', '');

  if (Number.isNaN(numString)) {
    return undefined;
  }

  return parseInt(numString, 10)
}

export {
  onResizeViewport,
  convertPxToNum,
}