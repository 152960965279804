const expireDate = new Date();
expireDate.setMonth(expireDate.getMonth() + 6);

const cookies = [
  {
    name: 'cookie-agreed',
    value: undefined, // set when user agrees to cookies
    expires: expireDate,
    category: 'essential',
  },
]

export default cookies;