import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initGoogleAnalytics } from '@services/analytics.service';

import CookieForm from './CookieForm/CookieForm';
import { setAgreedCookies } from './cookie-bar.redux.actions';
import { submitCookies, CookiesApi, daysFromNow } from './cookie-bar.utils';

import './cookie-bar.styl';

function CookieBar() {
  const agreedCookies = useSelector(state => state.cookieBar.agreedCookies);
  const dispatch = useDispatch();

  const onFormSubmit = useCallback((cookieCategorySelection) => {
    submitCookies(cookieCategorySelection).then((acceptedCookies) => {
      Object.keys(acceptedCookies).forEach((key) => {
        const cookieData = acceptedCookies[key];
        const { name, value, expires } = cookieData || {};

        if (name) {
          CookiesApi.set(name, value, {
            expires: expires ? daysFromNow(new Date(expires)) : 0,
          });
        }
      });

      dispatch(setAgreedCookies(acceptedCookies));
    });
  }, [dispatch]); 

  useEffect(() => {
    if (!agreedCookies) {
      return CookiesApi.remove('cookie-agreed');
    }
    
    if (agreedCookies.includes('analytical')) {
      initGoogleAnalytics();
    }
  }, [agreedCookies]);

  if (agreedCookies) {
    return null;
  }

  return (
    <div className="cookie-bar">
      <div className="cookie-bar__window">
        <div className="cookie-bar__window__header">
          <h1>This site uses cookies to ensure you get the best experience.</h1>
        </div>
        <div className="cookie-bar__window__body">
          <CookieForm onSubmit={cookieCategorySelection => onFormSubmit(cookieCategorySelection)}>
            <div className="cookie-bar__window__body__info">
              <p className="cookie-bar__window__body__info__read-more">
                <a
                  className="cookie-bar__window__body__info__read-more__url" 
                  href="https://legal.nazka.be/docs/en/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more about our cookie policy.
                </a>
              </p>
            </div>
          </CookieForm>
        </div>
      </div>
    </div>
  )
}

export default CookieBar;