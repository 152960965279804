import Icon from '@library/Icon/Icon';

import './icon-button.styl';

function IconButton(props) {
  const typeClass = `${props.classType ? ` icon-button--${props.classType}` : ''}`;
  return (
    <button
      type="button"
      className={
        'icon-button ' 
        + `${props.noHoverEffect ? '' : 'hover-effect'} `
        + `${typeClass} `
        + `${props.raised ? 'z-depth-1 ' : ''}` 
        + `${props.className ? `${props.className} ` : ''}`
      }
      data-tip={props['data-tip']}
      data-for={props['data-for']}
      aria-label={props.ariaLabel || ''}
      onClick={props.onClick}
      inert={props.inert}
      style={props.style}
    >
      <Icon 
        icon={props.icon} 
        color={props.iconColor} 
        className="icon-button__icon"
      />
    </button>
  );
}

export default IconButton;