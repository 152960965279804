import {
  useState, useMemo, useRef, useEffect, 
} from 'react';
import { useCombobox } from 'downshift';
import debounce from 'lodash.debounce';

import IconButton from '@library/IconButton/IconButton';
import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import './search.styl';

function Search(props) {
  const {
    contrast, inputDebounceMs = 0, dataSources, filters, 
  } = props;
  const [items, setItems] = useState([]);
  const inputElRef = useRef();
  const onInputValueChange = useMemo(() => debounce(({ inputValue }) => {
    setItems([]);

    if (!inputValue || (inputValue.length < 2)) {
      return;
    }

    dataSources.forEach(async (dataSource) => {
      const { index, onSearch } = dataSource;

      const dataSourceItems = await onSearch(inputValue);

      setItems((prevItems) => {
        return [
          ...prevItems.filter(item => (item.sourceIndex !== index)),
          ...dataSourceItems.map(item => ({ ...item, sourceIndex: index })),
        ].sort((x, y) => (x.sourceIndex - y.sourceIndex));
      });
    })
  }, inputDebounceMs), [inputDebounceMs, dataSources]);
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectItem,
  } = useCombobox({
    items,
    itemToString: item => (item ? item.label : ''),
    onInputValueChange,
    onSelectedItemChange: ({ selectedItem: newlySelectedItem }) => {
      if (newlySelectedItem || filters.location.place) {
        // when selection is cleared, newlySelectedItem will be null 
        const { sourceIndex } = (newlySelectedItem || filters.location.place);
        const dataSource = dataSources.find(curr => (curr.index === sourceIndex));

        dataSource.onItemSelect(newlySelectedItem, filters.location.place);
      }
    },
  });

  useEffect(() => {
    selectItem(filters.location.place);
  }, [filters, selectItem]);

  return (
    <div className={`search ${contrast ? 'search--contrast' : ''}`}>
      <div className="search__query">
        <IconButton 
          className="search__query__input__icon-button"
          icon={faSearch}
          iconColor={contrast ? '#FFF' : '#333'}
          ariaLabel="Search"
          noHoverEffect
          onClick={() => inputElRef.current.focus()}
        />
        <input {...getInputProps({ className: 'search__query__input', ref: inputElRef, placeholder: 'Search a location' })} />
        {filters.location.place && (
          <IconButton 
            className="search__query__input__icon-button"
            icon={faTimes}
            iconColor={contrast ? '#FFF' : '#333'}
            ariaLabel="Search"
            noHoverEffect
            onClick={() => selectItem(null)}
          />
        )}
      </div>
      <ul {...getMenuProps({ className: 'search__menu z-depth-1' })}>
        { isOpen
          && items.map((item, index) => (
            <li
              key={`${item.sourceIndex}-${item.id}`}
              {...getItemProps({
                className: `
                    search__menu__item
                    ${(highlightedIndex === index) ? 'search__menu__item--highlighted' : ''}
                    ${(filters.location.place && (filters.location.place.sourceIndex === item.sourceIndex) && (filters.location.place.id === item.id)) ? 'search__menu__item--selected' : ''}
                    ellipsis
                  `,
                item,
                index,
              })
              }
            >
              <span>{item.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Search;