export default [
  {
    id: 'projects',
    type: 'geojson',
    data: null,
    cluster: true,
    clusterRadius: 25,
  },
  {
    id: 'pipelines',
    type: 'geojson',
    data: null,
  },
];