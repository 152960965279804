import { Children } from 'react';
import {
  Routes, Route, Link, NavLink, Navigate, 
} from 'react-router-dom';

import RouteValidation from './RouteValidation/RouteValidation';

import { useTranslation } from './i18n.consumers';

const { routing } = I18N_CONFIG;

function LocalizedRoutes(props) {
  const { children } = props;
  const { lang } = useTranslation();
  
  return (
    <Routes>
      {routing ? Children.toArray(children).map((child) => {
        if (child.props.path && child.props.path !== '*') {
          return ([
            <Route 
              exact={child.props.exact} 
              path={child.props.path}
              element={<Navigate to={`/${lang}${child.props.path}`} replace />}
            />,
            <Route
              {...child.props}
              element={(
                <RouteValidation>
                  {child.props.element}
                </RouteValidation>
              )}
              path={`/:lang${child.props.path}`}
            />,
          ]);
        } return child;
      }) : children}
    </Routes>
  );
}

function localizeTo(to, lang) {
  if (!routing) return to;

  async function fx(location) {
    return localizeTo(await to(location))
  }
  
  switch (typeof to) {
    case 'object':
      return { ...to, pathname: `/${lang}${to.pathname}` };
    case 'function': 
      return fx;
    case 'string':
      return `/${lang}${to}`;
    default:
      return to;
  }
}

function useLocalizedTo(TranslatedComponent, props) {
  const { lang } = useTranslation();
  const to = routing ? localizeTo(props.to, lang) : props.to;
  const updatedProps = { ...props, to };
  return <TranslatedComponent {...updatedProps} />;
}

const LocalizedLink = props => useLocalizedTo(Link, props);

const LocalizedNavLink = props => useLocalizedTo(NavLink, props);

export {
  LocalizedRoutes,
  LocalizedLink,
  LocalizedNavLink,
}