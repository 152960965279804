import style from '@css/theme.style.json';

export default [
  {
    id: 'pipelines',
    type: 'line',
    source: 'pipelines',
    beforeId: 'place-label',
    filter: ['!=', ['get', 'status'], 1],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#C7E2C9',
      'line-width': 7,
    },
  },
  {
    id: 'pipelines-dashed',
    type: 'line',
    source: 'pipelines',
    beforeId: 'place-label',
    filter: ['!=', ['get', 'status'], 1],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#fff',
      'line-width': 2,
      'line-dasharray': [0.05, 2],
    },
  },
  {
    id: 'projects',
    type: 'symbol',
    source: 'projects',
    layout: {
      'icon-image': ['case',
        ['has', 'value_chain'], ['concat', 'value-chain-', ['get', 'value_chain']],
        'cluster-ball',
      ],
      'icon-anchor': ['case',
        ['has', 'value_chain'], 'top-right',
        'center',
      ],
      'icon-allow-overlap': true,
      'icon-size': ['case',
        ['has', 'value_chain'], 0.5,
        0.45,
      ],
      'icon-offset': [7, 0],
    },
  },
  {
    id: 'projects-cluster',
    type: 'symbol',
    source: 'projects',
    filter: ['has', 'point_count'],
    layout: {
      'icon-image': 'cluster-ball',
      'icon-anchor': 'center',
      'icon-allow-overlap': true,
      'icon-size': 0.45,
      'icon-offset': [7, 0],
    },
  },
  {
    id: 'projects-cluster-counters',
    filter: ['has', 'point_count'],
    type: 'symbol',
    source: 'projects',
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 16,
      'text-allow-overlap': true,
      'text-offset': [0.05, -0.1],
    },
    paint: {
      'text-color': '#ffffff',
    },
  },
  {
    id: 'projects-circle',
    type: 'symbol',
    source: 'projects',
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'circle-sdf',
      'icon-size': 0.26,
      'icon-anchor': 'top-right',
      'icon-allow-overlap': true,
      'icon-offset': [-5, 5],
    },
    paint: {
      'icon-color': [
        'match',
        ['get', 'status'],
        1, style.color.project['1'],
        2, style.color.project['2'],
        3, style.color.project['3'],
        4, style.color.project['4'],
        6, style.color.project['6'],
        style.color.project['5'],
      ],
    },
  },
];