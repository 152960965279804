function validation(formData, imageUploadRef) {
  if (!formData.name) {
    return 'Title field is required';
  }
  if (!formData.description) {
    return 'Description field is required';
  }
  if (!formData.contact.first_name) {
    return 'First name is required';
  }
  if (!formData.contact.last_name) {
    return 'Last name is required';
  }
  if (!formData.contact.organization) {
    return 'Organization is required';
  }
  if (!formData.contact.email) {
    return 'Email is required';
  }
  if (formData.image) {
    const { files } = imageUploadRef.current;
    if (files.length) {
      const filesize = ((files[0].size / 1024) / 1024).toFixed(4);
      if (filesize > 2.5) {
        return 'Images can not be larger than 2.5MB'
      }
    }
  }
  return null;
}

export { validation };