import { applyMiddleware, combineReducers, createStore } from 'redux';
import appWindow from '@utils/AppWindow/app-window.redux.reducers';
import cookieBar from '@utils/CookieBar/cookie-bar.redux.reducers';

const reducers = combineReducers({ 
  appWindow,
  cookieBar,
});

const createStoreArgs = [reducers];

if (import.meta.env.DEV) {
  const { createLogger } = await import('redux-logger');
  const logger = createLogger({
    collapsed: (_getState, _action, logEntry) => !logEntry.error,
  });

  createStoreArgs.push(applyMiddleware(logger));
}

export default createStore(...createStoreArgs);