function validation(formData) {
  if (!formData.long_description) {
    return 'Description is required';
  }
  if (!formData.contact.email) {
    return 'Email is required';
  }
  return null;
}

export { validation };