import axios from 'axios';

async function sendNewProjectEmail(formData) {
  const res = await axios.post(
    `/email/new-project?q=${JSON.stringify(formData)}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return res;
}

async function sendEditProjectEmail(formData) {
  const res = await axios.post(
    `/email/edit-project?q=${JSON.stringify(formData)}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
  return res;
}

export {
  sendNewProjectEmail,
  sendEditProjectEmail,
}