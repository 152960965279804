import axios from 'axios';

async function getSuggestions(searchParam) {
  const { data } = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchParam}.json`,
    { params: { access_token: import.meta.env.VITE_MAPBOX_TOKEN } },
  );
  return data;
}

export {
  getSuggestions,
}