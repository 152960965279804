import { useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '@utils/i18n/i18n.consumers';

import NoMatch from '@App/pages/NoMatch/NoMatch';

function RouteValidation({ children }) {
  const { lang: langParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang, languages } = useTranslation();

  const isValid = !languages.length || languages.includes(langParam);

  useEffect(() => {
    if (lang !== langParam && isValid) {
      const { pathname, search, hash } = location;
      navigate(`${pathname.replace(`/${langParam}`, `/${lang}`)}${search}${hash}`);
    }
  }, [lang, langParam, isValid, navigate, location]);

  return isValid ? children : <NoMatch />;
}

export default RouteValidation;