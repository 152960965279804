import { useQuery } from '@tanstack/react-query';
import { getProjectStatuses } from '@services/admin.service';
import Checkbox from '@library/Checkbox/Checkbox';
import style from '@css/theme.style.json';

import './status-select.styl';

function CustomLabel({ status }) {
  const { id, attributes: { name } } = status;
  return (
    <span className="status-select__row__checkbox__label">
      {name}
      <span 
        className="status-select__row__checkbox__label__status" 
        style={{ backgroundColor: style.color.project[id] }}
      />
    </span>
  )
}

function StatusSelect({ filters, setFilters }) {
  function updateSelectionArray(v) {
    if (filters.status.includes(v)) {
      setFilters({ 
        ...filters, 
        status: filters.status.filter(o => o !== v), 
      });
    } else {
      setFilters({ 
        ...filters, 
        status: [...filters.status, v], 
      });
    }
  }

  const { data: projectStatusesData } = useQuery({
    queryKey: ['projectStatuses'],
    queryFn: getProjectStatuses,
  });

  if (!projectStatusesData) return null;
    
  return (
    <div className="status-select">
      <div className="status-select__row">
        <Checkbox 
          name={projectStatusesData.find(s => s.id === 1).id}
          className="status-select__row__checkbox"
          label={(<CustomLabel status={projectStatusesData.find(s => s.id === 1)} />)}
          checked={filters.status.includes('1')}
          onChange={(e, c, v) => updateSelectionArray(v)}
        />
        <Checkbox 
          name={projectStatusesData.find(s => s.id === 2).id}
          className="status-select__row__checkbox"
          label={(<CustomLabel status={projectStatusesData.find(s => s.id === 2)} />)}
          checked={filters.status.includes('2')}
          onChange={(e, c, v) => updateSelectionArray(v)}
        />
      </div>
      <div className="status-select__row">
        <Checkbox 
          name={projectStatusesData.find(s => s.id === 3).id}
          className="status-select__row__checkbox"
          label={(<CustomLabel status={projectStatusesData.find(s => s.id === 3)} />)}
          checked={filters.status.includes('3')}
          onChange={(e, c, v) => updateSelectionArray(v)}
        />
        <Checkbox 
          name={projectStatusesData.find(s => s.id === 4).id}
          className="status-select__row__checkbox"
          label={(<CustomLabel status={projectStatusesData.find(s => s.id === 4)} />)}
          checked={filters.status.includes('4')}
          onChange={(e, c, v) => updateSelectionArray(v)}
        />
      </div>
      <div className="status-select__row">
        <Checkbox 
          name={projectStatusesData.find(s => s.id === 6).id}
          className="status-select__row__checkbox"
          label={(<CustomLabel status={projectStatusesData.find(s => s.id === 6)} />)}
          checked={filters.status.includes('6')}
          onChange={(e, c, v) => updateSelectionArray(v)}
        />
      </div>
    </div>
  )
}

export default StatusSelect;