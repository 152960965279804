/* eslint-disable max-lines */
import {
  useState, useEffect, useCallback, useRef, 
} from 'react';
import { sendEditProjectEmail } from '@services/email.service';
import Button from '@library/Button/Button';

import { validation } from './edit-project.utils';

import './edit-project.styl';

function NewProject({ project, setIsCompleted }) {
  const [emailHasBeenSend, setEmailHasBeenSend] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const imageUploadRef = useRef();
  const [formData, setFormData] = useState({
    name: '',
    id: null,
    description: '',
    image: '',
    contact: {
      first_name: '',
      last_name: '',
      organization: '',
      email: '',
    },
  });

  useEffect(() => {
    if (project) {
      setFormData(prev => ({ 
        ...prev, 
        name: project?.properties?.name || project,
        id: project?.id || project,
      })) 
    }
  }, [project]);

  const submitForm = useCallback(() => {
    const valMessage = validation(formData, imageUploadRef);
    if (valMessage) {
      setErrorMessage(valMessage);
    } else {
      const data = new FormData();
      if (formData.image.length) {
        const image = imageUploadRef.current.files[0];
        data.append('image', image);
      }
      data.set('data', JSON.stringify(formData));
      sendEditProjectEmail(data);
      setErrorMessage(null);
      setEmailHasBeenSend(true);
      setIsCompleted(true);
    }
  }, [formData, setIsCompleted]);

  if (emailHasBeenSend) {
    return (
      <div className="new-project new-project--success">
        <div className="new-project__header">
          <h2>Submit hydrogen initiatives</h2>
          <p>
            {`Thank you for your input about "${formData.name}". 
            The Belgian Hydrogen Council will have a look at your request 
            and will be be in touch with you.`}
          </p>
          <div className="new-project__header__logo" />
        </div>
      </div>
    )
  }

  return (
    <div className="edit-project">
      <div className="edit-project__header">
        <h2>Request changes</h2>
        <p>
          Is certain information incorrect (anymore)? Please let us know by filling out this form.
        </p>
        <div className="edit-project__header__logo" />
      </div>
      <div className="edit-project__body">
        <div className="edit-project__body__form">
          <div className="edit-project__body__form__group">
            <h3 className="edit-project__body__form__group__title">Hydrogen initiative data</h3>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">Title *</p>
              <input 
                value={formData.name}
                className="edit-project__body__form__group__field__input"
                readOnly
              />
            </label>
            <div className="new-project__body__form__group__field">
              <p className="new-project__body__form__group__field__label">Describe what should be updated *</p>
              <textarea 
                value={formData.description}
                onChange={e => setFormData(prev => ({ 
                  ...prev, description: e.target.value, 
                }))}
                className="new-project__body__form__group__field__textarea"
              />
            </div>
          </div>
          <div className="edit-project__body__form__group">
            <h3 className="edit-project__body__form__group__title">Contact details</h3>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">First name *</p>
              <input 
                value={formData.contact.first_name}
                onChange={e => setFormData(prev => ({ 
                  ...prev, contact: { ...prev.contact, first_name: e.target.value },
                }))}
                className="edit-project__body__form__group__field__input"
              />
            </label>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">Last name *</p>
              <input 
                value={formData.contact.last_name}
                onChange={e => setFormData(prev => ({ 
                  ...prev, contact: { ...prev.contact, last_name: e.target.value },
                }))}
                className="edit-project__body__form__group__field__input"
              />
            </label>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">Organisation *</p>
              <input 
                value={formData.contact.organization}
                onChange={e => setFormData(prev => ({ 
                  ...prev, contact: { ...prev.contact, organization: e.target.value },
                }))}
                className="edit-project__body__form__group__field__input"
              />
            </label>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">Email *</p>
              <input 
                type="email"
                value={formData.contact.email}
                onChange={e => setFormData(prev => ({ 
                  ...prev, contact: { ...prev.contact, email: e.target.value },
                }))}
                className="edit-project__body__form__group__field__input"
              />
            </label>
          </div>
          <div className="edit-project__body__form__group">
            <h3 className="edit-project__body__form__group__title">Images</h3>
            <label className="edit-project__body__form__group__field">
              <p className="edit-project__body__form__group__field__label">Photo or logo (jpg, png)</p>
              <input 
                type="file" 
                ref={imageUploadRef}
                className="edit-project__body__form__group__field__input"
                value={formData.image}
                onChange={e => setFormData(prev => ({ ...prev, image: e.target.value }))}
              />
            </label>
            <p className="edit-project__body__form__group__info">
              Photos, illustrations…
            </p>
          </div>
          <p className="edit-project__body__form__error">{errorMessage}</p>
          <Button
            className="edit-project__body__form__submit"
            onClick={() => submitForm()}
          >
            Submit form
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NewProject;