import NoMatch from '@App/pages/NoMatch/NoMatch';
import Mapview from '@App/pages/MapView/MapView';

const routes = [
  {
    name: 'MapView', 
    path: '/', 
    element: <Mapview />,
  },
  {
    name: 'MapView', 
    path: '/project/:projectSlug', 
    element: <Mapview />,
  },
  {
    name: 'MapView', 
    path: '/pipelines', 
    element: <Mapview />,
  },
  {
    name: 'Page Not Found', 
    path: '*', 
    element: <NoMatch />,
  },
];

export default routes;