async function addMarkersToMap(mapboxMap) {
  const imgFiles = import.meta.glob('/src/img/markers/*.png');

  await Promise.all(Object.keys(imgFiles).map((fileName) => {
    const index = fileName.lastIndexOf('/') + 1;
    const file = fileName.substr(index);
    return new Promise((resolve) => {
      mapboxMap.loadImage(`/img/markers/${file}`, (err, img) => {
        if (err) return;
        mapboxMap.addImage(file.replace(/\.[^/.]+$/, ''), img);
        resolve();
      });
    });
  }));
}

export { addMarkersToMap };