function getLoaderClass(type) {
  if (!type) {
    return 'is-loading';
  }

  switch (type) {
    case 'flat': 
      return 'is-loading is-loading--grey-light';
    default:
      return `is-loading is-loading--${type}`;
  }
}

export {
  getLoaderClass,
}