import { MEDIA_BREAKPOINT_ENTRIES } from './app-window.constants';

const defaultState = {
  resizeProps: {
    mediaBreakpoint: MEDIA_BREAKPOINT_ENTRIES[
      MEDIA_BREAKPOINT_ENTRIES
        .findIndex(entry => (entry[0] > window.innerWidth)) - 1 
    ][1],
  },
};

function appWindow(state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_RESIZE_PROPERTIES':
      return { 
        ...state, 
        resizeProps: {
          mediaBreakpoint: MEDIA_BREAKPOINT_ENTRIES[
            MEDIA_BREAKPOINT_ENTRIES
              .findIndex(entry => (entry[0] > window.innerWidth)) - 1
          ][1],
        },
      }
    default:
      return state;
  }
}

export default appWindow;