import { CookiesApi } from './cookie-bar.utils';

const defaultState = {
  agreedCookies: CookiesApi.get('cookie-agreed'),
};

function cookieBar(state = defaultState, action) {
  switch (action.type) {
    case 'SET_AGREED_COOKIES':
      return { 
        ...state,
        agreedCookies: action.payload,
      }
    default:
      return state;
  }
}

export default cookieBar;