import { useEffect, useState, useRef } from 'react';

import './logos.styl';

function Logos({ map }) {
  const [show, setShow] = useState(true);
  const prevZoom = useRef();

  useEffect(() => {
    if (!map) return;
    map.on('zoom', () => {
      const zoom = map.getZoom();
      setShow(zoom < 7.35);
      prevZoom.current = zoom;
    });
  }, [map]);

  return (
    <div className={`logos${!show ? ' logos--hide' : ''}`}>
      <div className="logos__img logos__img--fod" />
      <div className="logos__img logos__img--bhc" />
    </div>
  )
}

export default Logos;